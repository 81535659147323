import { isDate } from 'date-fns'
import { parseISO } from 'date-fns'
import { differenceInDays } from 'date-fns'
import { formatDistanceToNowStrict } from 'date-fns'
import { addHours } from 'date-fns'
import { getHours } from 'date-fns'
import { getMinutes } from 'date-fns'
import { getSeconds } from 'date-fns'

import { format } from 'date-fns-tz'
import { utcToZonedTime } from 'date-fns-tz'

import { DateTime } from 'luxon'

export const formatDate = (date, formatString = 'M/d h:mm a') => {
  try {
    let parsedDate
    if (isDate(date)) parsedDate = date
    else parsedDate = parseISO(date)
    const timeZone = 'America/New_York'
    const zonedDate = utcToZonedTime(parsedDate, timeZone)

    return format(zonedDate, formatString, { timeZone })
  } catch (err) {
    return 'Invalid Date'
  }
}
export const wordsOrDate = (date) => {
  if (differenceInDays(new Date(), new Date(date)) > 5) {
    return formatDate(date, 'M/d/yy h:mm a')
  }
  return `${formatDistanceToNowStrict(new Date(date))} ago`
}

/**
 * Get the difference between two Luxon DateTime objects with convenient defaults
 *
 * @param {DateTime} targetDateTime
 * @param {DateTime} currentDateTime
 */
export function getDateTimeInterval(
  targetDateTime,
  currentDateTime = DateTime.local(),
  format = ['days', 'hours', 'minutes', 'seconds']
) {
  return targetDateTime.diff(currentDateTime, format).toObject()
}

export const DAYS_OF_WEEK = Object.freeze([
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
])

export function getDayOfWeek(dateString) {
  const dateobj = new Date(dateString)
  return DAYS_OF_WEEK[dateobj.getDay()]
}

export const convertToDuration = (milliseconds) => {
  if (milliseconds === 0) return '00:00'
  if (!milliseconds || milliseconds < 1000) return null
  const normalizeTime = (time) => (time.length === 1 ? `0${time}` : time)

  const date = new Date(milliseconds)
  const timezoneDiff = date.getTimezoneOffset() / 60
  const dateWithoutTimezoneDiff = addHours(date, timezoneDiff)

  const hours = normalizeTime(String(getHours(dateWithoutTimezoneDiff)))
  const minutes = normalizeTime(String(getMinutes(dateWithoutTimezoneDiff)))
  const seconds = normalizeTime(String(getSeconds(dateWithoutTimezoneDiff)))

  const hoursOutput = hours !== '00' ? `${hours}:` : ''

  return `${hoursOutput}${minutes}:${seconds}`
}
