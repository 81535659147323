import React, { useEffect } from 'react'
import THEME from 'utils/theme'
import CloseIcon from 'icons/Close'

const Modal = ({ title = '', isOpen = false, close, children, maxWidth, padding }) => {
  const modalClass = `modalBackdrop ${isOpen ? 'displayBlock' : 'displayNone'}`

  useEffect(() => {
    const handleKeyDown = ({ key, code }) => {
      if (key === 'Escape' && code === 'Escape') close()
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [])

  return (
    <>
      <div className={modalClass}>
        <div className='modalForeground'>
          <div className='modalContent'>
            <div className='modalHeader'>
              <h1>{title}</h1>
              <div onClick={close} className='closeButton'>
                <CloseIcon />
              </div>
            </div>
            <div className='modalBody'>{children}</div>
          </div>
        </div>
      </div>
      <style jsx>{`
        @-webkit-keyframes fadeIn {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        @keyframes fadeIn {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        .modalBackdrop {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.6);
          z-index: 10;
        }
        .modalForeground {
          position: fixed;
          background: white;
          width: calc(100% - 2rem);
          max-width: ${maxWidth || '100%'};
          height: auto;
          max-height: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          overflow-y: scroll;
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .modalForeground {
            max-width: ${maxWidth || '900px'};
            max-height: 90%;
          }
        }
        .displayBlock {
          display: block;
          -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
          -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
          -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
        }
        .displayNone {
          display: none;
        }
        .modalContent {
          position: relative;
          width: 100%;
          max-height: 100%;
          padding: ${padding || '1rem'};
        }
        .modalHeader {
          width: 90%;
        }
        .modalHeader h1 {
          margin: 0 0 1rem;
          font-family: ${THEME.FONTS.BEBAS};
        }
        .closeButton {
          position: absolute;
          top: 2px;
          right: 2px;
          width: auto;
          padding: 1rem;
          cursor: pointer;
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .modalContent {
            padding: ${padding || '3rem'};
          }
        }
      `}</style>
    </>
  )
}

export default Modal
