import React from 'react'

import THEME from 'utils/theme'

const Checklist = ({ children }) => {
  return (
    <>
      <ul className='checklist'>{children}</ul>
      <style global jsx>{`
        .checklist {
          margin: 12px 0 0;
          padding: 0;
          text-align: left;
          list-style: none;
          display: block;
          line-height: 1.25;
        }
        .checklist li {
          font-family: ${THEME.FONTS.HELVETICA};
          font-size: 15.42px;
          letter-spacing: -0.29px;
          line-height: 19.27px;
          display: list-item;
          text-align: -webkit-match-parent;
          margin: 0;
          padding: 0;
          border: 0;
          vertical-align: baseline;
          position: relative;
          padding-left: 26px;
          margin-bottom: 0.5rem;
        }
        .checklist li:before {
          position: absolute;
          top: 5px;
          left: 0;
          content: '';
          width: 13px;
          height: 10px;
          background: url(/static/images/check-red.svg) no-repeat;
          background-size: 13px 10px;
        }
      `}</style>
    </>
  )
}

export default Checklist
