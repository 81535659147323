import React, { useState, useEffect, useRef, useMemo } from 'react'

const formatGifSrc = (src, format = 'mp4') => {
  const url = new URL(src)
  // set format to mp4 param to instruct fastly to convert to mp4
  url.searchParams.set('format', format)
  const cropParam = url.searchParams.get('crop')
  if (cropParam && format === 'mp4') {
    // filter out 'smart' from crop query param, breaks converted mp4s when present
    const cleanedCropParam = cropParam
      .split(',')
      .filter((val) => val !== 'smart')
      .join(',')
    // set cleaned crop query param back on url
    url.searchParams.set('crop', cleanedCropParam)
  }
  return url.href
}
function GifToVideo({ src }) {
  const videoRef = useRef()

  const [showFallback, setShowFallback] = useState(false)

  useEffect(() => {
    videoRef.current.defaultMuted = true
    // setting muted attribute directly on video element results in weird issues especially during testing
    // https://github.com/testing-library/react-testing-library/issues/470
    // https://github.com/facebook/react/issues/10389
    videoRef.current.muted = true
    videoRef.current.disableRemotePlayback = true
    videoRef.current.play()?.catch?.(() => {
      setShowFallback(true)
    })
  }, [])

  // formattedSrc has proper query params to take advantage of fastly's gif -> mp4 conversion
  const formattedSrc = useMemo(() => {
    return formatGifSrc(src)
  }, [src])

  // display fallback img as jpeg if video cannot be played
  const formattedFallbackSrc = useMemo(() => {
    return formatGifSrc(src, 'jpeg')
  }, [src])

  if (showFallback) {
    return <img src={formattedFallbackSrc} />
  }
  return (
    <video ref={videoRef} playsInline loop poster={formattedFallbackSrc} style={{ maxWidth: '100%' }}>
      <source src={formattedSrc} type='video/mp4' />
      <img src={formattedFallbackSrc} />
    </video>
  )
}

export default GifToVideo
