import React, { useState } from 'react'
import { useRouter } from 'next/router'
import { observer } from 'mobx-react-lite'
import { DateTime } from 'luxon'

import { useStore } from 'hooks'
import { AuthStore } from 'stores/auth'
import { sendEvent } from 'lib/ga'
import THEME from 'utils/theme'
import { formatDate, getDayOfWeek, getDateTimeInterval } from 'utils/time'
import { track } from 'lib/whoami'

import EmailCaptureModal from 'components/EmailCaptureModal'

const EventLink = observer(({ event, className = '', toCurrentEvent = true, ...props }) => {
  const [captureModalOpen, setCaptureModalOpen] = useState(false)
  const router = useRouter()
  const authStore = useStore(AuthStore)

  if (!event) {
    return null
  }

  const timeUntilEvent = getDateTimeInterval(DateTime.fromISO(event.metadata.sale_date))

  const copy = {
    live: 'Watch Now',
    onDemand: 'Watch Replay',
    upcoming: `Fights Start ${formatDate(event.date, 'M/d')} at ${formatDate(event.date, 'h:mm a')} ET`,
    presale: timeUntilEvent.days >= 7 ? `Don't Miss It` : `PPV Goes On Sale ${getDayOfWeek(event.metadata.sale_date)}`,
    noAccess: `Buy PPV $${event.price}`,
    ...props.copy
  }

  const handleClick = () => {
    if (event.status === 'presale') {
      setCaptureModalOpen(true)
    } else if (!toCurrentEvent) {
      router.push('/events')
    } else if (!authStore.loggedIn) {
      sendEvent(`Event ${event.id} Homepage`, 'Buy Button Click', { label: props.location })
      // whoami CDP
      track('ContentPurchaseStarted', {
        content: {
          barstoolEventID: event.id
        },
        cart: {
          totalPrice: event.price * 100,
          isPaymentMethodEntered: false,
          currencyIsoCode: 'USD'
        }
      })
      router.push({
        pathname: '/account/signin',
        query: {
          redirectHref: '/events/[id]/purchase',
          redirectAs: `/events/${event.id}/purchase`
        }
      })
    } else if (!authStore.hasEventAccess(event.id)) {
      sendEvent(`Event ${event.id} Homepage`, 'Buy Button Click', { label: props.location })
      // whoami CDP
      track('ContentPurchaseStarted', {
        content: {
          barstoolEventID: event.id
        },
        cart: {
          totalPrice: event.price * 100,
          isPaymentMethodEntered: false,
          currencyIsoCode: 'USD'
        }
      })
      router.push('/events/[id]/purchase', `/events/${event.id}/purchase`)
    } else {
      router.push('/events/[id]/watch', `/events/${event.id}/watch`)
    }
  }

  let buttonText
  if (event.status === 'presale') {
    // presale status trumps other behavior.
    buttonText = copy.presale
  } else if (!toCurrentEvent) {
    buttonText = 'Learn More'
  } else if (!authStore.loggedIn || !authStore.hasEventAccess(event.id)) {
    buttonText = copy.noAccess
  } else {
    switch (event.status) {
      case 'live':
        buttonText = copy.live
        break
      case 'on_demand':
        buttonText = copy.onDemand
        break
      case 'upcoming':
        buttonText = copy.upcoming
        break
      case 'presale':
        buttonText = copy.presale
        break
      default:
        // event.status === 'ended' and VOD is incoming
        // generally this would be 'Watch Replay'
        buttonText = copy.onDemand
    }
  }
  return (
    <>
      {captureModalOpen && (
        <EmailCaptureModal
          title="Don't Miss the Event:"
          isOpen={captureModalOpen}
          close={() => setCaptureModalOpen(false)}
          maxWidth='550px'
          event={event}
        />
      )}
      <a tabIndex='0' id='event-link' className={className} onClick={handleClick}>
        {buttonText}
      </a>
      <style jsx>{`
        #event-link {
          background-color: ${!toCurrentEvent ? 'black !important' : THEME.COLORS.PRIMARY};
        }
        @media (max-width: ${THEME.BREAKPOINTS.XS}px) {
          #event-link {
            padding: 12px 25px;
          }
        }
      `}</style>
    </>
  )
})

export default EventLink
