import React, { useState } from 'react'

import DateCountdown from 'components/DateCountdown'
import Checklist from 'components/Checklist'
import EventLink from 'components/EventLink'

import { formatDate } from 'utils/time'
import THEME from 'utils/theme'

function EventDetailActionPanel({
  event,
  linkToCurrentEvent = true,
  respectCountdownOption = true,
  videosPage = false
}) {
  const [hasStarted, setHasStarted] = useState(event?.status === 'live' && Date.now() > new Date(event?.date))
  const handleCountdownReached = () => setHasStarted(true)
  const ctaHeadline =
    videosPage && event?.metadata?.video_page_cta?.headline
      ? event?.metadata?.video_page_cta?.headline
      : event?.metadata?.cta?.headline
  const ctaList =
    videosPage && event?.metadata?.video_page_cta?.list?.length
      ? event?.metadata?.video_page_cta?.list
      : event?.metadata?.cta?.list

  const ctaTicketLink = !videosPage && event?.metadata?.cta_ticket_link

  return (
    <>
      <div className='panel panel--white'>
        <h2 className='panel__title'>{ctaHeadline}</h2>
        <p className='panel__date'>
          {(() => {
            switch (true) {
              case hasStarted:
                // return nothing if it is live and the event has started
                return
              case event?.status === 'on_demand':
                return 'NOW ON DEMAND'
              case respectCountdownOption &&
                event?.metadata?.countdown_active &&
                (event?.status === 'live' || event?.status === 'presale' || event?.status === 'upcoming'):
                return <DateCountdown targetDateISOString={event.date} onCountdownReached={handleCountdownReached} />
              default:
                return `${formatDate(event?.date, 'M/d/yyyy')} at ${formatDate(event?.date, 'ha')} ET`
            }
          })()}
        </p>
        {ctaList && (
          <Checklist>
            {ctaList.map((text, index) => (
              <li key={index}>{text}</li>
            ))}
          </Checklist>
        )}
        <p>
          <EventLink
            className='panel__btn btn--primary'
            event={event}
            location='hero-section'
            toCurrentEvent={linkToCurrentEvent}
          />
        </p>
        {ctaTicketLink?.active && (
          <a target='_blank' rel='noopener noreferrer' className='panel__link' href={ctaTicketLink?.destination}>
            {ctaTicketLink?.label}
          </a>
        )}
      </div>
      <style jsx>{`
        .panel {
          margin: auto;
          padding: 20px 40px;
          font-weight: normal;
          justify-content: center;
        }
        .panel--white {
          background: #fff;
          box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.3);
        }
        @media (max-width: ${THEME.BREAKPOINTS.SM}px) {
          .panel,
          .panel--white {
            padding: 10px 15px;
          }
        }
        .panel__title {
          font-family: ${THEME.FONTS.BEBAS};
          font-size: 30px;
          font-weight: normal;
          letter-spacing: 0.65px;
          line-height: 30px;
          text-align: center;
          margin: 0 0 12px;
        }
        .panel__date {
          font-family: ${THEME.FONTS.BEBAS};
          font-size: 50px;
          line-height: 1;
          letter-spacing: 0;
          text-align: center;
          margin: 0;
          margin-left: -50px; /* hack to make sure text stays centered even if overflowing container a bit */
          margin-right: -50px;
          text-align: center;
          white-space: nowrap;
        }
        .panel__link {
          display: block;
          width: 100%;
          margin: 0 auto;
          padding: 12px 0;
          font-size: 14px;
          color: ${THEME.COLORS.LINKCOLOR};
          text-align: center;
        }
        @media (max-width: 400px) {
          .panel__title {
            font-size: 24px;
          }
          .panel__date {
            font-size: 40px;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.MD}px) {
          .panel {
            padding: 20px;
            max-width: 500px;
          }
          .panel__date {
            font-size: 50px;
            line-height: 69px;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.LG}px) {
          .panel {
            padding: 20px 40px;
          }
        }
      `}</style>
    </>
  )
}

export default EventDetailActionPanel
