import { observable, flow, action } from 'mobx'
import { formsApi } from 'api'
import { CommonStore } from 'stores/common'

export const FormsStore = () => {
  const submissionInProgress = null

  const postSubmission = flow(function* (id, submission) {
    yield this.api.postSubmission(id, submission)
  })

  const startSubmissionInProgress = action(function ({ name = null, email = null, fields = [] }) {
    this.submissionInProgress = { name, email, fields }
  })

  const editSubmissionInProgress = action(function ({ name, email, fields }) {
    if (name) this.submissionInProgress.name = name
    if (email) this.submissionInProgress.email = email
    if (fields) this.submissionInProgress.fields = fields
  })

  const addFieldToSubmissionInProgress = action(function ({ prompt, answer }) {
    const findPromptIndex = this.submissionInProgress.fields.findIndex((field) => field.prompt === prompt)
    if (findPromptIndex !== -1) {
      // no duplicate prompts, overwrite
      this.submissionInProgress.fields.splice(findPromptIndex, 1)
    }
    this.submissionInProgress.fields.push({ prompt, answer })
  })

  const clearSubmissionInProgress = action(function () {
    this.submissionInProgress = null
  })

  return observable({
    ...CommonStore,
    api: formsApi,
    submissionInProgress,
    postSubmission,
    startSubmissionInProgress,
    editSubmissionInProgress,
    addFieldToSubmissionInProgress,
    clearSubmissionInProgress
  })
}

FormsStore.cacheKey = 'FormsStore'

export default FormsStore
