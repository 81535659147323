import React from 'react'
import Loading from 'icons/Loading'

const LoadingButton = ({ loading, className, children, ...props }) => {
  return (
    <button
      className={`loadingButton ${className}`}
      type='submit'
      disabled={loading}
      data-testid='LoadingButton'
      {...props}
    >
      {loading ? <Loading height={'1.25rem'} /> : children}
    </button>
  )
}

export default LoadingButton
