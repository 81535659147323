import React from 'react'

import EmailSubscribeForm from 'components/EmailSubscribeForm'
import Modal from 'components/Modal'

import { formatDate, getDayOfWeek } from 'utils/time'

const EmailCaptureModal = ({ isOpen, close, event, ...props }) => {
  const dateString = `${formatDate(event.date, 'h:mm a')} ET on ${getDayOfWeek(event.date)} ${formatDate(
    event.date,
    'MMMM d yyyy'
  )}`
  const preSubmitText = (
    <p>
      {event?.name} will be LIVE at {dateString}. Get on our email list to be updated as soon as {event?.name} is
      available for purchase.
    </p>
  )
  const postSubmitText = (
    <>
      <p className='thank-you-text'>
        Thanks, you're added to the list. Don't forget {event?.name} goes live at {dateString}. Check back at BuyRnR.com
        to purchase the event closer to that date and time.
      </p>
      <style jsx>{`
        .thank-you-text {
          text-align: left;
        }
      `}</style>
    </>
  )
  return (
    <Modal isOpen={isOpen} close={close} {...props}>
      <EmailSubscribeForm preSubmitText={preSubmitText} postSubmitText={postSubmitText} />
    </Modal>
  )
}

export default EmailCaptureModal
