import React, { useState } from 'react'
import Link from 'next/link'

import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'

import LoadingButton from 'components/LoadingButton'
import FormsStore from 'stores/forms'

import { sendEvent } from 'lib/ga'
import { useStore } from 'hooks'

const JoinNowSchema = Yup.object().shape({
  email: Yup.string().email('Please enter a valid email').required('This field is required')
})

const EmailSubscribeForm = ({ preSubmitText, postSubmitText }) => {
  const formsStore = useStore(FormsStore)
  const [hasSubmitted, setHasSubmitted] = useState(false)

  const handleSubmit = async (values, actions) => {
    actions.setSubmitting(true)
    try {
      await formsStore.postSubmission(process.env.WINTERIZED_FORM_ID, {
        email: values.email,
        fields: [{ prompt: 'email', answer: values.email }]
      })
      actions.setStatus({ message: 'Success', type: 'success' })
      sendEvent('Email Submission', 'Event Update', { label: document.location.pathname })
      setHasSubmitted(true)
    } catch (error) {
      actions.setStatus({ message: error.message })
    } finally {
      actions.setSubmitting(false)
    }
  }

  return (
    <>
      <div className='join-now-form'>
        {!hasSubmitted && preSubmitText}
        {hasSubmitted ? (
          <p className='thankyou'>
            {postSubmitText || (
              <strong>Thanks for signing up. We'll email about the next event when details are available.</strong>
            )}
          </p>
        ) : (
          <Formik
            initialValues={{ email: '' }}
            validationSchema={JoinNowSchema}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={handleSubmit}
          >
            {({ status, isSubmitting }) => (
              <Form noValidate>
                {status && status.message && <div className='form__error'>{status.message}</div>}
                <div className='formGroup'>
                  <Field className='formControl' type='email' name='email' placeholder='Email' />
                  <ErrorMessage className='field__error' name='email' component='div' />
                </div>
                <LoadingButton className='btn btn--primary' loading={isSubmitting}>
                  Join Now
                </LoadingButton>
                <p className='finePrint'>
                  By submitting this form I agree to this site's{' '}
                  <Link href='/terms-of-use' legacyBehavior>
                    <a>Terms of Use</a>
                  </Link>
                  {' and '}
                  <Link href='/privacy-policy' legacyBehavior>
                    <a>Privacy Policy</a>
                  </Link>
                  .
                </p>
              </Form>
            )}
          </Formik>
        )}
      </div>
      <style jsx>{`
        .join-now-form {
          padding: 10px 0;
        }
        .join-now-form strong {
          font-size: 24px;
        }
        .thankyou {
          text-align: center;
        }
      `}</style>
    </>
  )
}

export default EmailSubscribeForm
