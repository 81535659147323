import React, { useState, useMemo } from 'react'
import { DateTime } from 'luxon'

import { getDateTimeInterval } from 'utils/time'
import THEME from 'utils/theme'
import { useInterval } from 'hooks'

function DateCountdown({ targetDateISOString, onCountdownReached, color = THEME.COLORS.GRAY }) {
  const futureDateTime = useMemo(() => DateTime.fromISO(targetDateISOString), [targetDateISOString])
  const [timeInterval, setTimeInterval] = useState(getDateTimeInterval(futureDateTime))

  useInterval(
    () => {
      setTimeInterval(getDateTimeInterval(futureDateTime))
    },
    1000,
    [setTimeInterval, futureDateTime]
  )

  const hoursString = `${timeInterval.hours < 10 ? '0' : ''}${timeInterval.hours}`
  const minutesString = `${timeInterval.minutes < 10 ? '0' : ''}${timeInterval.minutes}`
  const secondsFixed = Number(timeInterval.seconds.toFixed(0))
  const secondsString = `${secondsFixed < 10 ? '0' : ''}${secondsFixed}`

  const existsNegativeTime = timeInterval.seconds < 0 || timeInterval.minutes < 0 || timeInterval.hours < 0
  const countdownReached =
    timeInterval.seconds === 0 && timeInterval.minutes === 0 && timeInterval.hours === 0 && timeInterval.days === 0
  if (existsNegativeTime || countdownReached) {
    onCountdownReached?.()
  }

  return (
    <>
      <section className='date-countdown'>
        <div className='countdown-backdrop'>
          <div className='backdrop-top-gradient' />
        </div>
        <div className='countdown-interval'>
          <div className='interval-piece'>
            <div className='interval-number'>{timeInterval.days}</div>
            <div className='interval-unit'>Days</div>
          </div>
          <div className='colon-separator'>:</div>
          <div className='interval-piece'>
            <div className='interval-number'>{hoursString}</div>
            <div className='interval-unit'>Hours</div>
          </div>
          <div className='colon-separator'>:</div>
          <div className='interval-piece'>
            <div className='interval-number'>{minutesString}</div>
            <div className='interval-unit'>Mins</div>
          </div>
          <div className='colon-separator'>:</div>
          <div className='interval-piece'>
            <div className='interval-number'>{secondsString}</div>
            <div className='interval-unit'>Sec</div>
          </div>
        </div>
      </section>
      <style jsx>{`
        .date-countdown {
          position: relative;
          font-family: ${THEME.FONTS.BEBAS};
          font-size: 50px;
          line-height: 120%;
          text-align: center;
          margin: 0 auto;
          width: 100%;
          z-index: 0;
        }
        .countdown-backdrop {
          background: linear-gradient(180deg, #000000 0%, #222222 99.48%);
          border-radius: 3px;
          transform: rotate(-180deg);
          position: absolute;
          top: 8px;
          left: 0;
          right: 0;
          height: 58px;
          max-width: 260px;
          margin: 0 auto;
          z-index: 0;
        }
        .backdrop-top-gradient {
          position: absolute;
          top: 50%;
          bottom: 0;
          width: 100%;
          background: linear-gradient(180deg, #000000 0%, #434343 99.48%);
          transform: rotate(-180deg);
          border-radius: 3px 3px 0px 0px;
        }
        .countdown-interval {
          z-index: 1;
          color: white;
          display: flex;
          justify-content: center;
          padding-top: 4px;
        }
        .interval-piece {
          display: flex;
          flex-direction: column;
        }
        .interval-number {
          padding: 4px 8px 0;
          z-index: 1;
        }
        .interval-unit {
          color: ${color};
          font-size: 13px;
          line-height: 15.6px;
        }
        .colon-separator {
          z-index: 1;
        }
      `}</style>
    </>
  )
}

export default DateCountdown
