import React from 'react'

const Arrow = ({ direction = 'up', width = '18px', height = '18px', color = '#333333' }) => {
  let degrees
  switch (direction) {
    case 'right':
      degrees = 90
      break
    case 'down':
      degrees = 180
      break
    case 'left':
      degrees = 270
      break
    default:
      degrees = 0
  }
  return (
    <svg viewBox='0 0 24 24' style={{ width, height, transform: `rotate(${degrees}deg)` }}>
      <defs>
        <path
          d='M10,3.99427406 L1.99918201,11.9950921 L-2.73558953e-13,9.99591004 L9.99591004,-2.73780998e-13 L10,0.00408995583 L10.00409,-2.77111667e-13 L20,9.99591004 L18.000818,11.9950921 L10,3.99427406 Z'
          id='arrow-path-1'
        ></path>
      </defs>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <mask id='arrow-mask-2' fill='white'>
          <use href='#arrow-path-1' />
        </mask>
        <g transform='translate(2.000000, 6.000000)'>
          <g mask='url(#arrow-mask-2)' fill={color}>
            <g transform='translate(-38.000000, -44.000000)'>
              <rect x='0' y='0' width='103' height='103'></rect>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Arrow
